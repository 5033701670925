import React from 'react'
import { DefaultLayout } from '../components/Layouts'
import PageTitle from '../components/PageTitle'
import Meta from '../components/Meta'

const SectionTitle = ({ children }) => (
  <h2 className="mb-8 mb-lg-0 sticky top-6 italic lowercase font-thin">
    {children}
  </h2>
)

const ItemName = ({ children }) => <h3 className="text-2xl mb-1">{children}</h3>

const ItemDesc = ({ children }) => (
  <p className="text-base leading-snug text-muted-400 mt-1 mb-4">{children}</p>
)

const ItemSubtitle = ({ children }) => (
  <strong className="lg:text-lg uppercase text-muted-900">{children}</strong>
)

const Separator = () => (
  <>
    <span className="px-2 hidden lg:inline">&middot;</span>
    <br className="lg:hidden" />
  </>
)

const JobItem = ({ items }) => {
  return (
    <>
      {items.map(item => (
        <div key={item.name} className="mb-16 lg:text-lg">
          {item.title && <ItemSubtitle>{item.title}</ItemSubtitle>}
          <div className="mt-4">
            <ItemName>{item.name}</ItemName>
          </div>
          <p className="text-base uppercase text-muted-400">
            {item.start && (
              <span>
                {item.start}&thinsp;&ndash;&thinsp;
                {item.end ? item.end : 'present'}
              </span>
            )}

            {item.location && (
              <>
                {item.start && <Separator />}
                {item.location}
              </>
            )}
          </p>
          {item.desc && <ItemDesc>{item.desc}</ItemDesc>}
          {item.bullets && (
            <ul className="mt-2">
              {item.bullets.map(bullet => (
                <li key={bullet} className="my-4">
                  {bullet}
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </>
  )
}

const SkillsItem = ({ items }) => {
  return (
    <ul className="mb-4">
      {items.map(item => {
        const subItems = item.items || []

        return (
          <li key={item.name} className="my-2">
            {item.name}
            {subItems && (
              <div className="text-muted text-sm">
                {subItems.map(subItem => subItem.name).join(', ')}
              </div>
            )}
          </li>
        )
      })}
    </ul>
  )
}

const GigItem = ({ items }) => {
  return (
    <>
      {items.map(item => (
        <div key={item.name} className="col sm:w-1/2 mb-8 lg:mb-6 lg:text-lg">
          <p className="my-1 text-xl">{item.name}</p>
          {item.desc && (
            <p className="text-base text-muted leading-snug">{item.desc}</p>
          )}
        </div>
      ))}
    </>
  )
}

const ResumeSection = ({ title, children }) => {
  return (
    <div className="flex flex-wrap lg:flex-no-wrap -mx-3 my-6 lg:my-16 relative justify-center">
      <div className="px-3 w-full lg:w-64 lg:mr-12 flex-grow-0 flex-shrink-0 mb-3 lg:text-right">
        <SectionTitle>{title}</SectionTitle>
      </div>
      <div className="px-3 w-full lg:w-7/12 lg:flex-shrink">{children}</div>
    </div>
  )
}

const ResumePage = ({ data }) => {
  const { education, employment, contract, designSkills, devSkills } = data
  return (
    <DefaultLayout>
      <Meta title="Resume" />
      <PageTitle>(Abbreviated) Resume</PageTitle>
      <ResumeSection title="Skills">
        <div className="row">
          <div className="col w-1/2 lg:w-5/12 lg:flex-shrink">
            <ItemSubtitle>Design & Marketing</ItemSubtitle>
            <SkillsItem items={designSkills.items} />
          </div>
          <div className="col w-1/2 lg:w-7/12 lg:flex-shrink">
            <ItemSubtitle>Development</ItemSubtitle>
            <SkillsItem items={devSkills.items} />
          </div>
        </div>
      </ResumeSection>
      <ResumeSection title={education.category} desc={education.desc}>
        <JobItem items={education.items} />
      </ResumeSection>
      <ResumeSection
        title={
          <>
            Employment & <br className="hidden lg:block" />
            Long-Term Clients
          </>
        }
        desc={employment.desc}
      >
        <JobItem items={employment.items} />
      </ResumeSection>
      <ResumeSection
        title="Contract Work & Special Projects"
        desc={contract.desc}
      >
        <p className="lg:text-lg mb-3">
          Since January 2019, I have been one half of North X South, a
          primarily-remote web development and graphic design company that works
          with small businesses and non-profits. From December 2009 to January
          2019, I freelanced solo as Blinding Stars.
        </p>
        <p className="lg:text-lg mb-8">
          Freelancing has allowed me to work with a multitude of technologies
          and hone my skills across the board; I am versatile and quick to
          adapt. Below is a non-exhaustive list of some of my roles:
        </p>
        <div className="row flex-wrap">
          <GigItem items={contract.items} />
        </div>
      </ResumeSection>
    </DefaultLayout>
  )
}

export const query = graphql`
  {
    designSkills: skillsJson(category: { eq: "design" }) {
      items {
        name
        items {
          name
        }
      }
      category
      id
    }
    devSkills: skillsJson(category: { eq: "dev" }) {
      items {
        name
        items {
          name
        }
      }
      category
      id
    }
    education: resumeJson(category: { eq: "Education" }) {
      items {
        name
        location
        title
        start(formatString: "MMM YYYY")
        end(formatString: "MMM YYYY")
        desc
        bullets
      }
      category
      id
    }
    employment: resumeJson(category: { eq: "Employment" }) {
      items {
        name
        location
        title
        start(formatString: "MMM YYYY")
        end(formatString: "MMM YYYY")
        desc
        bullets
      }
      category
      id
    }
    contract: resumeJson(category: { eq: "Contract" }) {
      items {
        name
        location
        title
        start(formatString: "MMM YYYY")
        end(formatString: "MMM YYYY")
        desc
        bullets
      }
      category
      id
    }
  }
`

export default ResumePage
